import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://osoz.${config.devbase}`
config.prodURL = 'https://cms.outdoorstyleoz.com.au'
config.siteURL = 'https://outdoorstyleoz.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.group_args.image_aspect = '4x3'
config.isEcommerce = false
config.sentryDsn = 'https://7f00139fbff34d7b8c97ec954442baeb@glitchtip.logger.jm1.au/12'

export { config }
