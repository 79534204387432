import { CognitoBase } from '~cognito/models/Cognito/Base'
import type { CognitoPhoto } from '~cognito/models/Cognito/Photo'

class CatalogueProduct extends CognitoBase {
  name: string
  description: string
  price: number
  photos: CognitoPhoto[]

  baseurl() {
    return '/api/v1/catalogue/product'
  }

  constructor(source?: Partial<CatalogueProduct>) {
    super()
    this.name = ''
    this.description = ''
    this.price = 0
    this.photos = []
    Object.assign(this, source)
  }
}

export { CatalogueProduct }
