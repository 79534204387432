<template>
  <div class="mx-auto flex flex-col gap-5">
    <div
      v-for="group in groups"
      :key="group.slug"
    >
      <router-link
        v-if="group.content && group.image.url"
        :to="`/catalogue/${group.slug}`"
        class="block rounded-lg shadow-lg overflow-hidden"
      >
        <div class="bg-white dark:bg-gray-800 gap-2 p-4 flex flex-col sm:flex-row justify-between">
          <cgn-lazy-image
            class="h-full w-full sm:w-40 object-cover rounded-md overflow-hidden"
            :image="group.image"
          />
          <div class="flex flex-1 flex-col pl-1">
            <div class="flex items-center gap-2 justify-between">
              <p
                class="text-xl font-semibold text-gray-900 dark:text-gray-100"
              >
                {{ group.name }}
              </p>
              <cgn-button color-brand>
                View Now
              </cgn-button>
            </div>
            <div
              class="text-sm prose prose-indigo dark:prose-dark"
              v-html="group.content"
            />
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'

const groups = ref<CognitoGroup[]>([])
const loadGroups = async () => {
  const data = await new CognitoGroup().find_many({
    namespace: 'Catalogue',
    model: 'Product',
    image_aspect: '4by3',
  })
  groups.value = data
}
onMounted(() => {
  loadGroups()
})
onServerPrefetch(async () => {
  await loadGroups()
})
</script>
