const __pages_import_0__ = () => import("/cognitocmsapi/pages/search/index.vue");
const __pages_import_1__ = () => import("/cognitocmsapi/pages/signup.vue");
const __pages_import_2__ = () => import("/cognitocmsapi/pages/profile.vue");
const __pages_import_3__ = () => import("/cognitocmsapi/pages/logout.vue");
const __pages_import_4__ = () => import("/cognitocmsapi/pages/login.vue");
import __pages_import_5__ from "/cognitocmsapi/pages/index.vue";
const __pages_import_6__ = () => import("/cognitocmsapi/pages/forgotpassword.vue");
const __pages_import_7__ = () => import("/cognitocmsapi/pages/demo_asdf.vue");
const __pages_import_8__ = () => import("/cognitocmsapi/pages/checkout.vue");
const __pages_import_9__ = () => import("/cognitocmsapi/pages/cart.vue");
const __pages_import_10__ = () => import("/src/pages/contact.vue");
const __pages_import_11__ = () => import("/cognitocmsapi/pages/[...all].vue");
const __pages_import_12__ = () => import("/cognitocmsapi/pages/invoice/[id].vue");
const __pages_import_13__ = () => import("/cognitocmsapi/pages/print-tickets/[id].vue");
const __pages_import_14__ = () => import("/cognitocmsapi/pages/search/[...query].vue");
const __pages_import_15__ = () => import("/cognitocmsapi/pages/show-invoice/[id].vue");
const __pages_import_16__ = () => import("/cognitocmsapi/pages/invoice/print/[id].vue");
const __pages_import_17__ = () => import("/cognitocmsapi/pages/show-invoice/print/[id].vue");

const routes = [{"name":"search","path":"/search","component":__pages_import_0__,"props":true},{"name":"signup","path":"/signup","component":__pages_import_1__,"props":true},{"name":"profile","path":"/profile","component":__pages_import_2__,"props":true,"meta":{"requiresAuth":true}},{"name":"logout","path":"/logout","component":__pages_import_3__,"props":true},{"name":"login","path":"/login","component":__pages_import_4__,"props":true},{"name":"index","path":"/","component":__pages_import_5__,"props":true},{"name":"forgotpassword","path":"/forgotpassword","component":__pages_import_6__,"props":true},{"name":"demo_asdf","path":"/demo_asdf","component":__pages_import_7__,"props":true},{"name":"checkout","path":"/checkout","component":__pages_import_8__,"props":true,"meta":{"requiresAuth":true}},{"name":"cart","path":"/cart","component":__pages_import_9__,"props":true},{"name":"contact","path":"/contact","component":__pages_import_10__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_11__,"props":true},{"name":"invoice-id","path":"/invoice/:id","component":__pages_import_12__,"props":true},{"name":"print-tickets-id","path":"/print-tickets/:id","component":__pages_import_13__,"props":true},{"name":"search-query","path":"/search/:query(.*)","component":__pages_import_14__,"props":true},{"name":"show-invoice-id","path":"/show-invoice/:id","component":__pages_import_15__,"props":true},{"name":"invoice-print-id","path":"/invoice/print/:id","component":__pages_import_16__,"props":true},{"name":"show-invoice-print-id","path":"/show-invoice/print/:id","component":__pages_import_17__,"props":true}];

export default routes;