<template>
  <div class="bg-black relative">
    <Swiper
      :slides-per-view="1"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }"
      :modules="swiperModules"
    >
      <SwiperSlide v-for="slide in slides" :key="slide.id">
        <cgn-lazy-image class="h-96 w-full opacity-40 object-cover" :image="slide.image" image-width="1920" image-aspect="16x9" force-size />
        <div class="inset-0 absolute z-10 pointer-events-none">
          <div class="max-w-6xl mx-auto px-2 py-4 lg:py-6 flex flex-col justify-between h-full">
            <div>
              <h2 class="text-3xl sm:text-4xl font-bold tracking-tight text-white">
                <span class="block sm:inline-block">{{ slide.name }}</span>
                <span class="block text-neutral-300 text-2xl sm:text-3xl">{{ slide.sub_heading_1 }}</span>
              </h2>
              <div class="block mt-3 text-sm sm:text-base leading-6 text-white max-w-3xl" v-html="slide.content" />
            </div>
            <div class="flex flex-col md:flex-row md:justify-between items-center text-center">
              <div class="text-white">
                {{ slide.footer }}
              </div>
              <cgn-button v-if="slide.link" color-brand :url="slide.link" class="w-full md:w-auto pointer-events-auto" extra-classes="w-full md:w-auto">
                {{ slide.link_button_text }}
              </cgn-button>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay } from 'swiper'
import { CognitoSlide } from '~cognito/models/Cognito/Slide'

import 'swiper/css'

const props = defineProps({
  templatevar: {},
})
const slides = ref<CognitoSlide[]>([])
const swiperModules = ref([Autoplay])
const loadGroup = async () => {
  const data = await new CognitoSlide().find_many({
    image_aspect: '16x9',
    image_width: 1920,
    page_size: 9,
    group: props.templatevar.slides,
  })
  slides.value = data.data
}
watch(() => props.templatevar.slides, loadGroup)
onMounted(() => {
  loadGroup()
})
</script>
