<template>
  <div
    class="relative bg-gradient-to-br from-gray-100 to-gray-300 dark:to-black dark:from-gray-700"
  >
    <cgn-breadcrumb :links="breadcrumbs" />
    <div class="sm:flex pt-16 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8 gap-10">
      <div class="w-full">
        <div class="text-center">
          <h1
            class="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-gray-100 sm:text-4xl"
          >
            Catalogue
            <div class="text-2xl sm:text-3xl font-bold text-indigo-500 dark:text-indigo-400">
              {{ groupDetails.name }}
            </div>
          </h1>
          <div class="prose prose-indigo dark:prose-dark mx-auto" v-html="groupDetails.content" />
        </div>
        <div class="mt-12 flex flex-col gap-2 items-center w-full">
          <div
            v-for="product in products"
            :key="product.id"
            class="flex flex-col md:flex-row rounded-lg shadow-lg overflow-hidden max-w-sm md:max-w-6xl w-full group cursor-pointer"
            @click="enlargeProduct(product)"
          >
            <div class="w-full max-w-sm relative bg-black">
              <cgn-lazy-image
                class="group-hover:opacity-70 transition-opacity duration-150"
                :image="product.photos[0].image"
              />
              <i-heroicons-solid:eye class="absolute m-2 top-0 left-0 text-white" />
            </div>
            <div class="bg-white dark:bg-gray-800 p-6 flex flex-col justify-between w-full">
              <div>
                <p class="text-xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ product.name }}
                </p>
                <div class="mt-3 text-base text-gray-500 dark:text-gray-200" v-html="product.description" />
              </div>
              <div class="mt-6 flex items-center">
                <p class="text-xl font-medium text-gray-900 dark:text-gray-100">
                  ${{ product.price }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <cgn-modal v-model="lightboxOpen">
    <template #clean-content>
      <div class="pt-5">
        <cgn-lazy-image
          class="w-full rounded-md"
          :image="selectedPhoto.image"
        />
        <div v-if="enlargedProduct.photos.length > 1" class="grid grid-cols-3 md:grid-cols-5 gap-2 pt-2">
          <div v-for="photo in enlargedProduct.photos" :key="photo.id" @click="selectedPhoto = photo">
            <cgn-lazy-image
              class="w-full rounded-md"
              :image="photo.image"
            />
          </div>
        </div>
      </div>
    </template>
  </cgn-modal>
</template>

<script setup lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { CatalogueProduct } from '~/models/Product'

const props = defineProps({
  group: {
    type: String,
    default: 'page',
  },
  page: {
    type: Number,
    default: 1,
  },
})

const products = ref<CatalogueProduct[]>([])
const groupDetails = ref<CognitoGroup>(new CognitoGroup())
const num_pages = ref(0)

const breadcrumbs = ref([{ name: 'Catalogue', url: '/catalogue/' }])

const enlargedProduct = ref<CatalogueProduct>(new CatalogueProduct())
const selectedPhoto = ref()
const lightboxOpen = ref(false)

const enlargeProduct = (product: CatalogueProduct) => {
  enlargedProduct.value = product
  selectedPhoto.value = enlargedProduct.value.photos[0]
  lightboxOpen.value = true
}

const loadCatalogue = async () => {
  products.value = []
  groupDetails.value.name = ''
  groupDetails.value.content = ''
  num_pages.value = 1
  let group = props.group
  const page = props.page
  if (group === 'page') {
    group = ''
  }
  breadcrumbs.value = [{ name: 'Catalogue', url: '/catalogue' }]
  const data = await new CatalogueProduct().find_many({
    image_aspect: '4by3',
    image_width: 600,
    page_size: 900,
    page,
    group,
  })
  products.value = data.data
  num_pages.value = data.num_pages

  if (group) {
    // Need the group to get heading etc
    const data = await new CognitoGroup().find_one({
      namespace: 'Catalogue',
      model: 'Product',
      group,
    })
    groupDetails.value = data
    breadcrumbs.value.push({ name: data.name, url: group })
  }
}
watch(() => props, () => {
  loadCatalogue()
}, {
  deep: true,
})
onMounted(() => {
  loadCatalogue()
})
onServerPrefetch(async () => {
  await loadCatalogue()
})
</script>
